<template>
  <div class="leet-basis-page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/news' }">新闻中心</el-breadcrumb-item>
        <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="page-body-content">
      <div class="article">
        <div class="title">{{ info.title?.rendered }}</div>
        <div class="date">
          <span><i class="el-icon-time"></i> {{ info.date | formatdate }}</span>
          <span><i class="el-icon-view"></i> {{ info.views }}</span>
        </div>
        <div class="richtext" v-html="htmlContent"></div>
        <div class="controls">
          <a :style="{ pointerEvents: previousInfo.id ? 'auto' : 'none' }" :href="`/news/detail/${previousInfo.id}`" :title="previousInfo.title || '没有了'" class="page-prev"
            ><span>{{ previousInfo.title || "没有了" }}</span></a
          >
          <a :style="{ pointerEvents: nextInfo.id ? 'auto' : 'none' }" :href="`/news/detail/${nextInfo.id}`" title="nextInfo.title || '没有了'" class="page-next"
            ><span>{{ nextInfo.title || "没有了" }}</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail, updateNewsViews, adjacentPosts } from "@/api/news";
const dayjs = require("dayjs");
export default {
  name: "basis-page",
  components: {},
  data() {
    return {
      postId: this.$route.params.id,
      info: {},
      previousInfo: {},
      nextInfo: {},
      htmlContent: "",
    };
  },
  mounted() {
    this.updateView();
    this.getDetail();
    this.getAdjacentPosts();
  },
  filters: {
    formatdate(datetime) {
      return dayjs(datetime).format("YYYY-MM-DD");
    },
  },
  created() {},
  methods: {
    getDetail() {
      getNewsDetail(this.postId).then((response) => {
        this.info = response.data;
        this.htmlContent = response.data.content.rendered.replace(/http:\/\/39\.105\.101\.79:8080/g, "/wpimg");
      });
    },
    updateView() {
      updateNewsViews(this.postId).then(() => {});
    },
    getAdjacentPosts() {
      adjacentPosts(this.postId).then((response) => {
        const adjacent = response.data.adjacent_posts;
        if (adjacent.previous) {
          this.previousInfo = adjacent.previous;
        }
        if (adjacent.next) {
          this.nextInfo = adjacent.next;
        }
      });
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="less">
.leet-basis-page {
  box-sizing: border-box;
  .breadcrumb {
    margin: 20px auto;
    padding: 0 10px;
  }
  .page-body-content {
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    .article {
      padding-bottom: 70px;
      font-family: PingFangSC-Regular, PingFang SC;
      .title {
        font-size: 16px;
        line-height: 28px;
        padding: 0 10px;
        font-weight: bold;
        text-align: center;
        margin: auto;
      }
      .date {
        text-align: center;
        margin: 14px auto 14px auto;
        display: flex;
        max-width: 70%;
        justify-content: space-between;
        color: #888;
        font-size: 14px;
      }
      .richtext {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 10px;
        padding: 0 10px;
      }
      .controls {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background: #f0f0f0;
        a {
          color: #323232;
          text-decoration: none;
        }
        .page-prev,
        .page-next {
          height: 16px;
          line-height: 16px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .page-prev::before {
          content: "上一篇：";
          margin-right: 5px;
        }
        .page-next::before {
          content: "下一篇：";
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
